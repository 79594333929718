.footer {
    text-align: center;
}

#tcs {
    width: 50%;
    max-width: 400px;
    border-radius: 5px;
    box-shadow: 5px 5px 10px 5px rgba(0, 0, 0, 0.3);
}

#tcs, 
.foot-txt {
    margin: 10px 10px;
}